import Axios from "../Axios";
import { HandleRoutingMessages } from "../utils";

// a service created for getting placement data from db.
async function getPlacementQuestions() {
	try {
		const data = await Axios.get(`api/partners/placement-test`);
		return data.data.data;
	} catch (e) {
		// HandleRoutingMessages(e.response.status);
		console.log(e.message);
	}
}

// a service created for setting placement data from db.
async function postPlacementAnswers(answers, data) {
	let answersPosted = {
		end_time: data.end,
		start_time: data.start,
		answers: answers,
	};
	try {
		const result = await Axios.post(
			`api/partners/placement-test-result`,
			answersPosted
		);
		return result.data;
	} catch (e) {
		// HandleRoutingMessages(e.response.status);
		console.log(e.message);
	}
}

async function getUserLevel() {
	try {
		const userLevel = await Axios.get(`api/classes/progress`);
		return userLevel?.data?.data;
	} catch (e) {
		// HandleRoutingMessages(e.response.status);
		console.log(e.message);
	}
}
async function getEnterpriseDetails() {
	try {
		const enterpriseDetails = await Axios.get(`api/partners/enterpriseDetails`);
		return enterpriseDetails.data.data;
	} catch (e) {
		// HandleRoutingMessages(e.response.status);
		console.log(e.message);
	}
}

async function getSurveyQuestions() {
	try {
		const data = await Axios.get(`api/partners/survey`);
		return data.data.data;
	} catch (e) {
		// HandleRoutingMessages(e.response.status);
		console.log(e.message);
	}
}
async function sendSurveyQuestions(data) {
	try {
		const sendSurvey = await Axios.post(`api/partners/survey`, data);
		return sendSurvey.data;
	} catch (e) {
		// HandleRoutingMessages(e.response.status);
		console.log(e.message);
	}
}

// a service created for getting levels data from db.
async function getLevel() {
	try {
		const getLvl = await Axios.get(`api/levels`);
		return getLvl.data;
	} catch (e) {
		// HandleRoutingMessages(e.response.status);

		// throw new Error(e.message);
	}
}

export {
	getPlacementQuestions,
	postPlacementAnswers,
	getUserLevel,
	getLevel,
	getSurveyQuestions,
	sendSurveyQuestions,
	getEnterpriseDetails,
};
