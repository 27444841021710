import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	getSurveyQuestions,
	sendSurveyQuestions,
} from "../../services/AssessmentService";
import SingleQuestions from "../../components/reusable-components/SingleQuestions";
import MultiQuestions from "../../components/reusable-components/MultiQuestions";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

const WelcomeSurvey = () => {
	const { t } = useTranslation();
	const naviagte = useNavigate();
	const [surveyList, setSurveyList] = useState({});
	const [multiChoices, setMultiChoices] = useState([]);
	const [singleChoices, setSingleChoices] = useState([]);
	const [openSnackBar, setOpenSnackBar] = useState(false);

	// TODO Get Survey Questions From APIs Response
	const surveyQuestions = async () => {
		const response = await getSurveyQuestions();
		setSurveyList(response);
	};

	// TODO Handle add & remove filter Choices
	const CheckHandler = (e, type) => {
		const { id } = e.target;
		const index = multiChoices.indexOf(id);
		switch (type) {
			case 1: // This Case For Single Choices Questions [Radio]
				setSingleChoices([id]);
				break;
			case 2: // This Case For Multi Choices Questions [Checkbox]
				if (index !== -1) {
					setMultiChoices([
						...multiChoices.slice(0, index),
						...multiChoices.slice(index + 1),
					]);
				} else {
					setMultiChoices([...multiChoices, id]);
				}
			default:
				return;
		}
	};
	// TODO Handle Display Survey Question on HTML
	const DisplayQuestionsType = (ques, type) => {
		switch (type) {
			case 1:
				return (
					<SingleQuestions
						onClick={(e) => CheckHandler(e, type)}
						id={ques.id}
						values={ques.name}
						checkedStatus={
							singleChoices.find((cur) => cur == ques.id) ? true : false
						}
					/>
				);
			case 2:
				return (
					<MultiQuestions
						onClick={(e) => CheckHandler(e, type)}
						id={ques.id}
						values={ques.name}
					/>
				);
			default:
				return;
		}
	};

	// TODO Get Final Result And Send TO Survey APIs
	const handleSurveyAnswer = () => {
		localStorage.setItem("surveyTaken", true);

		const data = {
			age_group: singleChoices,
			learning_reasons: multiChoices,
		};
		sendSurveyQuestions(data);
		setOpenSnackBar(true);
		naviagte("/");
	};

	// TODO Excute Survey Questions From APIs [First Rendering]
	useEffect(() => {
		surveyQuestions();
	}, []);

	if (Object.keys(surveyList).length === 0)
		return (
			<div className="attend-assessment__spinner">
				<div
					className="spinner-grow englease__bg--main attend-assessment__spinner--circle"
					role="status"
				></div>
				<p className="mx-3 mb-0 englease__bold englease--f-26 englease__txt--muteTxt">
					Loading...
				</p>
			</div>
		);
	return (
		<div className="attend-assessment container">
			<section className="row g-0 englease text-center">
				{/* Title */}
				<div className="col-12 englease__bold englease--f-38 mb-5">
					{surveyList?.survey_title}
				</div>
				{/* Survey Questions [Holder] */}
				<div className="col-12">
					{surveyList?.partner_survey?.map((element, idx) => {
						return (
							<section key={idx}>
								{/* Survey Questions [Title] */}
								<p className="englease--f-18 attend-assessment__survey-title">
									{element.title}
								</p>
								<div
									className={`${
										element.type == 1 && "attend-assessment__survey-list  my-4"
									}`}
								>
									{element?.values?.map((ques, idx) => {
										return (
											<div key={idx} className="englease--f-16">
												{DisplayQuestionsType(ques, element.type)}
											</div>
										);
									})}
								</div>
							</section>
						);
					})}
				</div>
				<div className="col-12">
					<button
						disabled={
							singleChoices?.length == 0 || multiChoices.length < 2
								? true
								: false
						}
						className={`${
							singleChoices?.length == 0 || multiChoices.length < 2
								? "englease__bg--mute englease__txt--black"
								: "englease__bg--main englease__txt--white"
						} attend-assessment__btn mx-2 border-0  englease__radius--f-8 `}
						onClick={() => handleSurveyAnswer()}
					>
						{/* {t("assessment-test.assessment-btn")}  */}
						Submit Survey
						{openSnackBar === true && (
							<span
								className="spinner-border spinner-border-sm mx-3"
								role="status"
								aria-hidden="true"
							></span>
						)}
					</button>
				</div>
			</section>
		</div>
	);
};
export default WelcomeSurvey;
