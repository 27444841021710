import React, { useState } from "react";
import "./styles.scss";

const MultiQuestions = (props) => {
  const { onClick, values, id } = props;
  const [isChecked, setIsChecked] = useState(false);

  const getChecked = (e) => {
    setIsChecked(e.target.checked);
  };
  return (
    <div className={`checkbox-wrapper-30 englease--click englease__radius--f-8 mb-4   
    ${isChecked == true && "englease__bg--main--20 englease__border--main" }`}
    >
      <label className="d-flex align-items-center p-3 gap-3 englease--click" onClick={(e) => getChecked(e)}>
        <span className="checkbox ">
          <input type="checkbox" id={id} onClick={onClick} value={values} />
          <svg>
            <use xlinkHref="#checkbox-30" className="checkbox"></use>
          </svg>
        </span> 
        <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
          <symbol id="checkbox-30" viewBox="0 0 22 22">
            <path
              fill="none"
              stroke="currentColor"
              d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
            />
          </symbol>
        </svg>
        <span>{values}</span>
      </label>
    </div>
  );
};
export default MultiQuestions;