import axios from "axios";
import { getCookie, HandleRoutingMessages } from "../utils";

const Axios = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	mode: "cors",
	// timeout: 100
});

// Add a request interceptor
Axios.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		let enterpriseToken = sessionStorage.getItem("token");
		config.headers = {
			...config.headers,
			Authorization:
				enterpriseToken !== null ? `Bearer ${enterpriseToken}` : "Bearer ",
			"Access-Control-Allow-Headers":
				"Origin, X-Requested-With, Content-Type, Accept",
			"Access-Control-Allow-Origin": "*",
			"content-Type": "application/json",
			Accept: "application/json",
			Language: getCookie("i18next"),
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		};
		return config;
	},
	function (error) {
		// Do something with request error
		error.toJSON();
		return Promise.reject(error);
	}
);

// Add a response interceptor
Axios.interceptors.response.use(
	(response) => {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		// response;
		return !response.data.success ? response : response;
	},
	(error) => {
		try {
			if (error.response.status >= 400) {
				if (error.response.status === 401) {
					sessionStorage.clear();
					// location.assign("/");
					// return Promise.reject(HandleRoutingMessages("authorization-err"));
				} else if (error.response.status === 400) {
					console.error(error.response?.data);
				} else {
					console.error(error);
					// return Promise.reject(HandleRoutingMessages("something-worng-err"));
				}
			}
			if (error.response.status >= 500) {
				// return Promise.reject(HandleRoutingMessages("something-worng-err"));
				// location.assign("");
			}
		} catch {
			console.log(error);
			localStorage.clear();
			sessionStorage.clear();
			// location.assign("/");
			// return Promise.reject(HandleRoutingMessages("server-err"));
		}
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		return Promise.reject(error);
	}
);

export default Axios;
