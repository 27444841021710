import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { actions } from "../../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLevel, getUserLevel } from "../../../services/AssessmentService";
import "./styles.scss";
import Counter from "../../../components/reusable-components/Counter";

const AssessmentResult = (props) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [levelList, setLevelList] = useState([]);
	const studentResult = useSelector((state) => state.assessmentResult);
	const placementResult = useSelector((state) => state.placementResult);
	const [userLevel, setUserLevel] = useState();
	const handleLocation = useLocation();
	// async function getUserCurrentLevel() {
	// 	const res = await getUserLevel();
	// 	setUserLevel(res.name);
	// }
	// // Get levels list from service.
	// const AssessmentsLvl = async () => {
	// 	const data = await getLevel();
	// 	setLevelList(data.data);
	// };
	const handleRetake = () => {
		dispatch(actions.clearAnswerList([]));
		navigate(handleLocation.state.page.name, { state: handleLocation.state.page.key });
	};
	const handleAvailableClasses = () => {
		dispatch(actions.clearAnswerList([]));
		if (studentResult?.type === "assessment") {
			navigate(handleLocation.state?.page.key);
		} else if (placementResult.type === "placement") {
			navigate("/");
		}
	};
	const testResult = () => {
		let score = "";
		if (studentResult?.type === "assessment") {
			score = Number(studentResult?.score?.percentage);
		} else {
			score =
				localStorage.getItem("i18nextLng") === "ar"
					? placementResult?.level?.name_ar
					: placementResult?.level?.name;
		}
		let homeworkPassed = studentResult?.score?.is_passed;
		// window["dataLayer"].push({
		// 	event: homeworkPassed ? "homework_passed" : "homework_failed",
		// 	level: userLevel,
		// });
		return score;
	};
	// useEffect(() => {
	// 	AssessmentsLvl();
	// 	getUserCurrentLevel();
	// }, []);


	const handleRedirect = () => {
		// location.assign("https://google.com")
		window.location.replace(handleLocation?.state?.redirect)

	}
	

	return (
		<div className="assessment-result">
			<>
				<h4 className="assessment-result__title">
					{studentResult?.type === "assessment"
						? t("assessment-result.test-result")
						: t("placement-result.level-set")}
				</h4>
				<p>
					{studentResult?.type === "assessment"
						? studentResult?.score?.is_passed === false
							? t("assessment-result.fail.not-completed_successfully")
							: t("assessment-result.success.completed_successfully")
						: t("placement-result.congrats")}
				</p>
				<i
					className={
						studentResult?.type === "assessment"
							? studentResult?.score?.is_passed === false
								? "assessment-result__sad-icon"
								: "assessment-result__celebration-icon"
							: "assessment-result__celebration-icon"
					}
				></i>
				<p>
					{studentResult?.type === "assessment"
						? t("assessment-result.your-score-is")
						: t("placement-result.your-level-is")}
				</p>
				<p
					className={
						studentResult?.type === "assessment"
							? ""
							: "assessment-result__score"
					}
				>
					{testResult()}
				</p>
					{/* <p
						dangerouslySetInnerHTML={{
							__html: `${
								localStorage.getItem("i18nextLng") === "ar"
									? placementResult?.level?.description_ar
									: placementResult?.level?.description
							}`,
						}}
						className={
							studentResult?.type === "assessment"
								? ""
								: "assessment-result__score"
						}
					></p> */}

				<p
					dangerouslySetInnerHTML={{
						__html: handleLocation.state.result?.level_name}}
					className={
						studentResult?.type === "assessment"
							? ""
							: "assessment-result__score englease englease--f-20"
					}
				></p>
				<div>
						{/* <p className="">
							{studentResult?.type === "assessment"
								? studentResult?.score?.is_passed === false
									? t("assessment-result.fail.alright-let's-try-again")
									: t("assessment-result.success.congrats")
								: t("placement-result.your-dream-is-one-step-closer")}
						</p> */}
					<p className="englease englease--f-16" dangerouslySetInnerHTML={{
						__html: handleLocation.state.result?.level_description}}>
					</p>

					{studentResult?.score?.is_passed === false && (
						<button
							className="btn englease__radius--f-8 assessment-result__retakeTest"
							onClick={handleRetake}
						>
							{t("assessment-result.fail.retake-test")}
						</button>
					)}
				</div>
				{/* {studentResult?.type === "assessment" ? (
					<>
						<button
							className="btn mx-2 border-0 englease__bg--main englease__radius--f-8 englease__txt--white"
							onClick={handleAvailableClasses}
						>
							{t("assessment-result.available-classes")}
						</button>
					</>
				) : (
					<button
						className="btn mx-2 border-0 englease__bg--main englease__radius--f-8 englease__txt--white"
						onClick={handleAvailableClasses}
					>
						{t("placement-result.view-all-classes")}
					</button>
				)} */}
				<div className="assessment-result__redirect">
					<button
						className="btn mx-2 border-0 englease__bg--main englease__radius--f-8 englease__txt--white"
						onClick={handleRedirect}
						>
						Redirect Now
					</button>
					<div className="d-flex align-items-center gap-3 englease">
					<p className="m-0  englease--f-18">
						we are redirecting you in
					</p>
						<Counter countNumber={10} redirect_URL={handleLocation?.state?.redirect} />
					</div>
						</div>
			</>
		</div>
	);
};

export default AssessmentResult;
