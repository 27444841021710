import React, { lazy, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { actions } from "../../redux/store";
import { ThemeContext } from "../../App";
import Header from "../Header";

import ThankYou from "../../pages/ThankYou";
import WelcomeSurvey from "../../pages/WelcomeSurvey";
import AssessmentResult from "../../pages/TakeAssessmentTest/AssessmentResult";

const TakePlacementTest = lazy(() => import("../../pages/TakeAssessmentTest"));
const Playground = lazy(() => import("../../pages/Playground"));
const NotFound = lazy(() => import("../../pages/NotFound"));
const PlacementTest = lazy(() =>
	import("../../pages/TakeAssessmentTest/PlacementTest")
);

import "./styles.scss";

const MasterContainer = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const surveyToogle = useSelector((state) => state.surveyAccess);
	const theme = useContext(ThemeContext);
	const token = sessionStorage.getItem("token");

	const routes = [
		{ path: "", element: <TakePlacementTest /> },
		{ path: "thank-you", element: <ThankYou /> },
		{ path: "welcome-survey", element: <WelcomeSurvey /> },
		{ path: "assessment-result", element: <AssessmentResult /> },
		{ path: "attend-placement-test", element: <PlacementTest /> },
		{ path: "*", element: <NotFound /> },
	];
	// Tracking token existance if not it will render the login page
	useEffect(() => {
		const surveyTaken = localStorage.getItem("surveyTaken");
		if (
			theme?.settings?.survey_page === true &&
			surveyToogle === false &&
			surveyTaken === null
		) {
			dispatch(actions.ToogleSurvey(true));
			navigate("welcome-survey");
		} else {
			// navigate("/");
		}
	}, [theme?.settings?.survey_page]);

	return (
		<>
			{!token ? (
				<Routes>
					<Route path="" element={<Playground />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			) : (
				<>
					<header className="">
						<Header />
					</header>
					<section
						className={`master-container container-fluid ${
							location.pathname === "/practice-videos" ? "p-0" : "py-4"
						}`}
					>
						{/* Render Here All Pages */}
						<Routes>
							{routes.map((el, ix) => {
								return <Route key={ix} path={el.path} element={el.element} />;
							})}
						</Routes>
					</section>
					{/* <footer className="p-3 text-black text-center">
				<Footer />
			</footer> */}
				</>
			)}
		</>
	);
};

export default MasterContainer;
