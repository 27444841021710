import React, {
	Suspense,
	useLayoutEffect,
	useEffect,
	createContext,
	useState,
} from "react";
import { actions } from "./redux/store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import MasterContainer from "./layout/MasterContainer";
import { getEnterpriseDetails } from "./services/AssessmentService";
import "./App.scss";

export const ThemeContext = createContext({});

const App = () => {
	const dispatch = useDispatch();
	const { i18n } = useTranslation();
	const [theme, setTheme] = useState({});
	const localLang = localStorage.getItem("i18nextLng", "en");

	// TODO Get Survey Questions From APIs Response
	const enterpriseData = async () => {
		const response = await getEnterpriseDetails();
		setTheme(response);
	};

	useLayoutEffect(() => {
		// calling API for getting enterprise theme object
		// make the theme work based on the domain  - check the domain - //
		// < write your code here instead of the two below lines >
		const urlParams = new URLSearchParams(location.search);
		const enterpriseToken = urlParams.get("token");
		enterpriseToken ? sessionStorage.setItem("token", enterpriseToken) : "";
	}, []);

	useEffect(() => {
		enterpriseData();
	}, []);

	useEffect(() => {
		i18n?.changeLanguage(localLang);
		switch (localLang) {
			case "ar":
				dispatch(actions.handleArClass(true));
				break;
			default:
				dispatch(actions.handleArClass(false));
				break;
		}
	}, [localLang]);

	useEffect(() => {
		if (
			theme?.theme !== undefined &&
			theme?.theme !== null &&
			Object.keys(theme).length !== 0
		) {
			document.documentElement.style.setProperty(
				"--color-main",
				`${theme?.theme?.primary_color}`
			);
			document.documentElement.style.setProperty(
				"--color-Lavender",
				`${theme?.theme?.primary_color}`
			);
			document.documentElement.style.setProperty(
				"--color-nav",
				`${theme?.theme?.header?.text_color}`
			);
		}
	}, [theme]);

	function loader() {
		return (
			// to be a reusable component for multiple usage
			<div className="text-center justify-content-center align-middle">
				<div className="align-items-center flex-nowrap spinner">
					<img
						src={`${
							theme?.theme?.logo
								? theme?.theme?.logo
								: "/assets/images/logooc.webp"
						}`}
						width="300"
						height="80"
						alt="englease-logo"
					/>
				</div>
			</div>
		);
	}

	return (
		<ThemeContext.Provider value={theme}>
			<Suspense fallback={loader()}>
				<section
					className={`app ${localLang == "ar" ? "englease-rtl" : "englease"}`}
				>
					<main className="content-wrapper">
						<MasterContainer />
					</main>
				</section>
			</Suspense>
		</ThemeContext.Provider>
	);
};
export default App;
