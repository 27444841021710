export const InitState = {
	answersList: [],
	checkedAnswersList: [],
	arClass: false,
	user: {},
	assessmentResult: "",
	placementResult: "",
	token: "",
	surveyAccess: false,
};
