import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { saveLanguageToCookie } from "../../utils";
import "./styles.scss";
import { ThemeContext } from "../../App";
const lngs = {
	en: { nativeName: "EN" },
	ar: { nativeName: "AR" },
};

const Header = () => {
	const theme = useContext(ThemeContext);
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const navigate = useNavigate();
	const isTabletOrMobile = window.screen.width;
	const SendToken = (type, status) => {
		// window["dataLayer"].push({
		// 	event: "renew_clicked",
		// 	parameter_screen: "header",
		// });
		navigate(`/${type}`);
	};

	const handleLang = useCallback((lng) => {
		saveLanguageToCookie(lng);
		localStorage.setItem("i18nextLng", lng);
		location?.reload();
	}, []);

	return (
		<section
			className="container-fluid py-4 border-bottom border-1 border-light"
			style={{ backgroundColor: theme?.header?.background_color }}
		>
			<div className="row align-items-center flex-nowrap gap-4">
				{/* Logo Image  */}
				<figure className="col d-flex align-items-center flex-nowrap mb-0">
					{/* <NavLink to="/" aria-label="englease.com"> */}
					<picture>
						<img
							src={`${
								"/assets/images/Engleaselogo.jpg"
							}`}
							className="englease__bg--contain "
							alt="englease-logo"
							width="117"
							height="22"
						/>
					</picture>
					{/* </NavLink> */}
				</figure>

				<section className="col d-none d-lg-flex">
					{/* Icons Section */}
					<div className="col d-flex gap-4 align-items-center justify-content-end">
						<Dropdown>
							<Dropdown.Toggle
								aria-label="dorpMenu-lang"
								variant=""
								id="language-dropdown"
								className="p-0 d-flex"
							>
								<img
									src="/assets/icons/Global.jpg"
									className="englease__bg--contain uET-click "
									alt="englease-Global"
									width="21"
									height="21"
								/>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{Object.keys(lngs).map((lng, idx) => (
									<Dropdown.Item
										className={
											isTabletOrMobile < "768px"
												? "dropdown-menu-lang iET-Global englease__bg--contain "
												: ""
										}
										style={{
											fontWeight:
												i18n.resolvedLanguage === lng ? "bold" : "normal",
											color: i18n.resolvedLanguage === lng ? "#244d95" : "",
										}}
										key={idx}
										onClick={() => handleLang(lng)}
									>
										{lngs[lng].nativeName}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</section>

				<Dropdown className="col gap-4 justify-content-end align-items-center d-flex d-lg-none">
					<Dropdown>
						<Dropdown.Toggle
							variant=""
							id="lang-dropdown"
							className="p-0 d-flex"
							name="language"
							aria-label="language"
						>
							<i className="iET-Global englease__bg--contain  iET-s21 uET-click"></i>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{Object.keys(lngs).map((lng, idx) => (
								<Dropdown.Item
									className={
										isTabletOrMobile < "768px"
											? "dropdown-menu-lang iET-Global englease__bg--contain "
											: ""
									}
									style={{
										fontWeight:
											i18n.resolvedLanguage === lng ? "bold" : "normal",
										color: i18n.resolvedLanguage === lng ? "#244d95" : "",
									}}
									key={idx}
									onClick={() => handleLang(lng)}
								>
									{lngs[lng].nativeName}
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</Dropdown>
			</div>
		</section>
	);
};
export default Header;
