import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Counter from "../../components/reusable-components/Counter";
import "./styles.scss";
const ThankYou = () => {
  const location = useLocation()
  return (
    <>
    <div className="thank-you">
      <div className="sa">
        <div className="sa-success">
          <div className="sa-success-tip"></div>
          <div className="sa-success-long"></div>
          <div className="sa-success-placeholder"></div>
          <div className="sa-success-fix"></div>
        </div>
      </div>
      <div className="typing englease  englease__txt--black">
        <p className="m-0">Thank you for choosing our website</p>
        <p className="m-0">
          we are redirecting you in <Counter countNumber={11} redirect_URL={location?.state?.redirect} />
        </p>
      </div>
    </div>

    <div className="checkmark"></div>
    </>
  )
}
export default ThankYou;