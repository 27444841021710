import React, { useEffect, useState } from 'react'

const Counter = (props) => {
  const {countNumber, redirect_URL} = props
  const [redirectLink, setRedirectLink] = useState(countNumber);
  useEffect(() => {
    if (redirectLink === 0) {
      window.location.replace(redirect_URL)
      return;
    }
    const timerId = setTimeout(() => {
      setRedirectLink(() => redirectLink - 1);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [redirectLink]);
  return (
    <span className="englease__bold englease--f-38 englease__txt--main"> {redirectLink} </span>
  )
}

export default Counter